// Note: 維持 Key: [string]; 不換行, 方便語系比對閱讀
const notify_style = 'font-size: 24px; font-style: italic; font-weight: 500; color: #303133'

const en = {
  /* project */
  system_name: 'Mobile AI Video Platform',
  system_language: 'Language',
  system_timezone: 'Timezone',
  system_default: 'System presets',

  /* global */
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
  ok: 'OK',
  save: 'Save',
  close: 'Close',
  delete: 'Delete',
  confirm: 'Confirm',
  finish: 'Finish',
  error: 'Error',
  edit: 'Edit',
  view: 'View',
  none: 'None',
  custom: 'Custom',
  total: 'Total',
  loading: 'Loading',
  selected: 'Selected',
  search: 'Search',
  pre: 'Previous',
  next: 'Next',
  stop: 'Stop',
  unknown: 'Unknown',
  tag: 'Tag',
  license_plate_example: 'ABC-1234',
  on: 'On',
  off: 'Off',
  device: 'Device',
  vehicle: 'Vehicle',
  mission: 'Mission',
  name: 'Name',
  attribute: 'Attribute',
  file: 'File',
  link: 'Link',
  update_time: 'Update time',
  all: 'All',
  adv_search: 'Adv. Search', // Advanced search
  search_result: 'Result：{count}',
  new_tag_hint: 'New Tag',
  event: 'Event',
  decibel_meter: 'Decibel meter',
  hint: 'Hint',
  collapse: 'Collapse',
  expand: 'Expand',
  search_fail_title: 'Search fail',
  no_subscription: '未登記',
  delete_data: 'Delete data',
  delete_data_confirm: 'Are you sure to delete {count} data(s)?',
  age: '{age}Y',
  upload: 'Upload',
  uploading: 'Uploading...',
  success: 'Successed',
  fail: 'Failed',
  check: 'Check',
  create: 'Create',
  show_all: 'Show all',
  data_processing: 'In Progressing...',
  enable: 'Enable',
  enabled: 'Enabled',
  disabled: 'Disabled',
  search_placehold: 'Please choose',
  nothing_modified: 'Nothing modified',
  no_data: 'No Data',
  select_all: 'Select all',
  action: 'Action',
  required: 'Required',
  hub_reset: 'Hub reset',
  reboot: 'Reboot',

  /* alarm */
  data_error: 'Data Error',

  /* language */
  lang_zh: '正體中文',
  lang_en: 'English',
  lang_ja: '日本語',

  /* week & time */
  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  month: 'months',
  day: 'days',
  one_day: 'day',
  n_day: '{day} day(s)',
  hour: 'hours',
  min: 'minutes',
  n_min: '{min} minute',
  one_min: 'minute',
  sec: 'seconds',
  n_sec: '{sec} second',
  month_ago: 'months ago',
  day_ago: 'days ago',
  hour_ago: 'hours ago',
  min_ago: 'minutes ago',
  sec_ago: 'seconds ago',
  one_min_later: 'minute later',
  min_later: 'minutes later',
  today: 'Today',
  yesterday: 'Yesterday',
  last_week: 'Last week',
  last_month: 'Last month',
  last_three_months: 'Last three months',
  half_year_ago: 'Half year ago',
  custom_time: 'Custom time',
  parameter: 'Parameter',

  /* login */
  login_id: 'Account',
  login_pw: 'Password',
  login_hint: 'Please enter account and password!',
  login_account_password_error: 'Wrong username or password.',
  login_permission_hint: 'This account has no permission to use the system, please connect administrator.',
  login_npa_hint: 'Please contact your system administrator',
  login_clean_cache_head: 'Data Handled',
  login_clean_cache_msg: 'Are you sure you want to clear your browser cache data?',
  login_clean_cache_btn_tooltip: 'Clear Cache',

  /* Api */
  api_400: 'Missing required fields.',
  api_401: `Wrong username or password.`,
  api_401_login: `Wrong username or password.`,
  api_401_account_disabled: 'Your account has been disabled.',
  api_401_account_suspended: 'Your account has been disabled. Please contact your system administrator.',
  api_403: `Permission denied`,
  api_403_duplicate_password: 'The password has been used in the last three attempts.',
  api_404: `Not Found`,
  api_404_login: `Wrong username or password.`,
  api_404_default_setting: 'Default setting not found',
  api_404_user_setting: 'User setting not found',
  api_500: `Internal Server Error`,
  api_error: `An unexpected error has occurred. Please contact your system administrator.`,
  api_econnaborted: 'Network connection has been lost.',
  api_timeout: 'The request had timed out.',

  /* left bar */
  left_bar_dashboard: 'Dashboard',
  left_bar_history: 'Event Records',
  // left_bar_subscription: 'Subscription',
  left_bar_subscription: 'Matching Database',
  left_bar_recognition: 'Matching Database',
  left_bar_lpr_recognition: 'Plate Number Management',
  left_bar_fr_recognition: 'Person Management',
  left_bar_account_management: 'Account and Permissions',
  left_bar_system_setting: 'System and Services',
  left_bar_log: 'Logs',
  left_bar_download_app: 'Download Center',
  left_bar_export_center: 'Export Center',
  left_bar_account: 'Account',
  left_bar_call: 'Voice Call',
  left_bar_joined_call: 'Joined call',
  left_bar_left_call: 'Left call',
  left_bar_end_call_before: 'Please end the current call before initiating a new one.',
  left_bar_cant_call_myself: 'Cannot make a call to oneself.',
  left_bar_15_seconds_hangup: 'Call automatically gets disconnected after 15 seconds.',
  left_bar_failed_sdp_speak: 'Failed to exchange SDP information or send voice notification. The call will be disconnected.',
  left_bar_another_webpage_call: 'You have opened a call on another web page, you must close it to reopen the call',
  left_bar_other_has_hungup: 'The other party has hung up the phone',
  left_ber_userinfo_change_password: 'Change Password',
  left_bar_userinfo_username_placeholder: 'Account name(Limit {max} characters)',
  left_bar_userinfo_email_placeholder: 'Email',
  left_bar_userinfo_phone_placeholder: 'Mobile Number',
  left_ber_userinfo_change_password_sso_hint: 'SSO account cannot change password',

  /* top bar */
  top_bar_keep_login: 'Keep login',
  top_bar_online_devices: 'Online Devices',
  top_bar_show_recog_frame: 'Live Recognition Frame',
  top_bar_calling_volume_off: 'During call, volume muted.',
  top_bar_calling_volume_reverse: 'The call ended and the volume has been restored.',

  /* 下載APP */
  download_notify_app: 'Notify APP',
  download_info: 'Please use your personal mobile phone to scan the QR code and download the notification APP',
  download_android: 'Android',
  download_ios: 'iOS',
  download_manual: 'Manual',
  download_manual_fn: 'Manual.pdf',
  download_tool: 'Tool',
  download_search_placeholder: 'Search',
  download_name: 'Name',
  download_version: 'Version',
  download_update_time: 'Update time',
  download_size: 'File size',
  download_os: 'OS',
  download: 'Download',
  download_no_info: 'No info',

  /* 匯出中心 */
  export_fail: 'Export failed',
  export_fail_hint: 'The number of transactions exceeds {count}, please narrow the search scope',
  export_category: 'Category',
  export_filename: 'File name',
  export_expiration_time: 'Expiration time',
  export_file_size: 'File size',
  export_status_download: 'download',
  export_status_fail: 'Export failed',
  export_status_exporting: 'Exporting',
  export_status_deleting: 'Deleting',
  export_warning_title: 'Unable to export data',
  export_warning_content: 'Export center reached a limit of 5 records. Please clear old data.',
  export_warning_filename: 'Filename cannot exceeds {count} characters',
  export_result: 'Export result',
  export_custom_filename: 'Filename',
  export_license_plate_picture: 'Export license plate picture',
  export_snapshot: 'export snapshot',
  export_license_plate_picture_embedded: 'License plate picture embedded in the report',
  export_photo_warning: 'The number of entries exceeds {count}, so the image cannot be exported. If you want to export the image, please narrow the search range.',
  export_search_criteria: 'Search Criteria',
  export_plate_number: 'Plate Number',
  export_time_interval: 'Time Interval',
  export_event_category: 'Event Category',
  // export_mission: 'Mission',
  export_finished_condition: 'Finished Condition',
  export_category_basic: 'Basic Search',
  export_category_advanced: 'Advanced Search',
  export_category_performance: 'Performance Search',
  export_category_audit: 'Audit Search',
  export_basic: 'Basic',
  export_adv: 'Adv',
  export_lpr_default_purpose: 'Business Management',

  /* 二因子認證-2FA */
  twoFactorAuth_title: 'Two-Factor Authentication',
  twoFactorAuth_installApp: 'Use Verification APP',
  twoFactorAuth_installApp_desc1: 'For extra protection,please use apps Google Authenticator or Duo Mobile to generate verification codes.',
  twoFactorAuth_installApp_desc2: `If you haven't installed the authentication application, please install it first to proceed to the next step.`,
  twoFactorAuth_scanQrCode: 'Setting by Third-Party Verification App',
  twoFactorAuth_scanQrCode_desc: 'Please scan the QR code with your authenticator app (such as DUO or Google Authenticator).',
  twoFactorAuth_inputCode: 'Please input 2FA code',
  twoFactorAuth_inputCode_login: 'Please input 2FA code',
  twoFactorAuth_inputCode_desc: 'Please input the 2FA code from the verification APP.',
  twoFactorAuth_done: 'Enabled',
  twoFactorAuth_done_desc: 'The Two-Factor Authentication of this account is already  enabled',
  twoFactorAuth_enabled_pass: 'Successed to enable',
  twoFactorAuth_enabled_fail: 'Failed to enable',
  twoFactorAuth_disabled_pass: 'Successed to disable',
  twoFactorAuth_disabled_fail: 'Failed to diabled',
  twoFactorAuth_validate_pass: 'Successed to validate',
  twoFactorAuth_validate_fail: 'Failed to validate',
  twoFactorAuth_error_getQrCode: 'Failed to get QR Code',
  twoFactorAuth_error_inputNumber: 'Please input the number',
  twoFactorAuth_error_fullCode: 'Please input completed 2FA code',

  /* account */
  account_title: 'Account',
  account_name: 'Name',
  account_role: 'Role',
  account_change_password: 'Change Password',
  account_device_change_password: 'Change Authorization Code',
  account_disable_2fa: 'Disable Two-Factor Authentication',
  account_id_tip: 'Please enter {min}-{max} characters, letters, numbers or any special symbols are allowed',
  account_id_length_error: 'Account string length must be {min}-{max} characters',
  account_id_char_error: 'Account only allow alpha letters, numbers, uppercase and lowercase letters, and special characters',
  account_edit_password: 'Change password',
  account_device_edit_password: 'Change authorization code',
  account_edit_password_tip1: '1. The length must be {min}～{max} characters.',
  account_edit_password_tip2: '2. Please enter any one special characters.',
  account_pw_tip: 'Please enter {min}-{max} characters, including alpha letters, numbers and any special symbols',
  account_new_pw: 'New Password',
  account_device_new_pw: 'New Authorization Code',
  account_reenter_pw: 'Re-enter Your New Password',
  account_device_reenter_pw: 'Re-enter Your New Authorization Code',
  account_pw_length_error: 'The length must be {min}-{max} characters.',
  account_pw_char_error: 'It only allow alpha letters, numbers, uppercase and lowercase letters, and special characters',
  account_pw_special_char_error: 'It mush have any one special characters',
  account_pw_error: 'The two passwords do not match, please confirm!',
  account_device_pw_error: 'The two authorization codes do not match, please confirm!',
  account_pw_null: 'It cannot be empty!',
  account_pw_success: 'Changed successfully.',
  account_pw_failed: 'Changed failed',
  account_2fa: 'Two-Factor Authentication',
  account_logout: 'Logout',
  account_logout_hint: 'Are you sure you want to leave?',
  account_delete_confirm: 'Confirm delete',
  account_notify_edit_title: 'Edit account information',
  account_notify_edit_success: 'Account information modified successfully',
  account_notify_edit_failed: 'Error editing account',
  account_search_role: 'Search Role',
  account_add_role: 'Create Role',
  account_edit_role: 'Edit Role',
  account_copy_role: 'Copy Role',
  account_delete_role: 'Delete Role',
  account_add: 'Create account',
  account_add_success: 'Account successfully added.',
  account_add_failed: 'Error adding account',
  account_add_failed_account_exist: 'This account already exists. Please fill it in again.',
  account_required: '<span class="required">*</span> Required',
  account_detail_group_accound: 'Group and Account',
  account_detail_group: 'Group',
  account_detail_id: 'Account',
  account_detail_password: 'Password',
  account_device_detail_password: 'Authorization Code',
  account_required_info: 'required',
  account_viewing_quota: 'Viewing quota',
  account_multi_select: 'Multiple selection accounts',
  account_cancel_multi_select: 'Cancel multiple selection mode',
  account_selected: 'Selected account',
  account_batch_selected_account_only: 'Display Selected Account Only({count})',
  account_batch_edit_items: 'Batch edit items',
  account_add_items: 'Add items',
  account_abnormal_account: 'Abnormal account',
  account_filter_result: 'Quantity：{count}',
  account_batch_modified: 'The user have been modified in batches',
  account_batch_n: '{pass_count} user(s) successfully and {fail_count} user(s) failed.',
  // account_n_success: 'successful updated',
  // account_n_failed: 'failed',
  account_active: 'Active',
  account_not_activated: 'Disabled',
  account_enabled: 'Enabled',
  // account_disabled_until: 'Disabled until',
  account_disabled: 'Disabled',
  account_suspend_view: 'Suspended until {time}',
  account_suspend_edit: 'Suspend time',
  account_status: 'Used Status',
  account_status_enabled: 'Enabled',
  account_status_disable: 'Disabled',
  account_status_locked: 'Suspend',
  account_enable_confirm_title: 'Enable Account',
  account_enable_confirm_msg: 'Do you want to enable account {name}?',
  account_disable_confirm_title: 'Disable Account',
  account_disable_confirm_msg: 'Do you want to disable account {name}?',
  account_group: 'Group',
  account_id: 'Account',
  account_password: 'Password',
  account_device_password: 'Authorization Code',
  account_confirm_password: 'Confirm password',
  account_device_confirm_password: 'Confirm authorization code',
  account_detail_name: 'Name',
  account_all: 'All',
  account_public_title: 'Public Status',
  account_public_all: 'All',
  account_public_0: 'Private',
  account_public_1: 'Public',
  account_default_setting: 'Config Source',
  account_defaultSetting_1: 'System Default',
  account_defaultSetting_0: 'Device Customize',
  account_watermark: 'Watermark',
  account_watermark_0: 'Disabled',
  account_watermark_1: 'Enabled',
  account_detail_linkmode_title: 'Linked', // 勾稽
  account_linkmode_title: 'Link Mode',
  account_linkmode_all: 'All',
  account_linkmode_0: 'Disabled',
  account_linkmode_1: 'BoviLive',
  account_linkmode_2: 'BoviLive - forced',
  account_linkmode_3: ' NPA',
  account_linkmode_4: 'NPA - forced',
  account_linkmode_group: 'Group',
  account_linkmode_user: 'User',
  account_type: 'Account type',
  account_kind_default: 'Default',
  account_kind_sso: 'SSO',
  account_kind_device: 'Device',
  account_kind_user: 'User',
  account_kind_0: 'Default',
  account_kind_1: 'SSO',
  account_kind_2: 'Device',
  account_kind_3: 'User',
  account_label: 'Account name',
  account_lang: 'Language',
  account_tz: 'Timezone',
  account_video_title: 'Video title',
  account_detail_property: 'Property',
  account_lpr_keep: 'Event keep',
  account_video_keep: 'Video keep',
  account_keep_day: 'Keep Day',
  account_video_keep_day: 'Video',
  account_fr_keep_day: 'FR',
  account_lpr_keep_day: 'LPR',
  account_or_keep_day: 'Object',
  account_device_model: 'Device type',
  account_device_model_0: 'Other',
  account_device_series_10: 'BoviCam Series',
  account_device_series_100: 'AiCam Series',
  account_device_series_200: 'Bodyworn Cam Series',
  account_device_series_500: 'FaceArk Series',
  account_device_series_1000: 'Android APP Series',
  account_device_series_2000: 'iOS APP Series',
  account_device_series_3000: 'Mac Series',
  // account_device_series_4000: 'VirtualCam Series',
  account_ip_source: 'IP source',
  account_gps: 'GPS',
  account_from: 'Account source',
  account_last_action: 'Latest action',
  account_note: 'Note',
  account_time: 'Time',
  account_login_time: 'Login Time',
  // account_keep_day: 'Keep Day',
  account_updated_time: 'Updated time',
  account_register_time: 'Register time',
  account_locked: 'Account locked',
  account_15min_later: '15 min later',
  account_30min_later: '30 min later',
  account_1hr_later: '1 hour later',
  account_edit_title: 'Edit Account',
  account_edit_device_model_hint: '請注意, 變更設備類型時會一併清空既存設備參數及事件接收人清單',
  account_edit_failed: 'Failed to edit account',
  account_edit_success: 'Successfully updated {name}',
  account_lock_success: 'Successfully locked {name}',
  account_lock_failed: 'Lock error occurred.',
  account_unlock: 'Unlock account',
  account_unlock_success: 'Successfully unlocked {name}',
  account_unlock_failed: 'Unlock error occurred.',
  account_unlock_confirm_title: 'Unlock Account',
  account_unlock_confirm_msg: 'Do you want to unlock account {name}?',
  account_source_sso: 'SSO',
  account_source_self: 'Self',
  account_forced_2fa: '2FA',
  account_forced2fa_0: 'On Demand',
  account_forced2fa_1: 'Forced',
  account_enabled_2fa: 'Enabled 2FA',
  account_not_lived: 'Not lived device',
  account_change_password_sso_hint: 'SSO account cannot reset password',
  account_forced_2fa_hint: 'Not force to use 2FA yet',
  account_note_park_direction: 'Direction',
  account_note_park_direction_0: 'Entrance',
  account_note_park_direction_1: 'Exit',
  account_note_park_vehicle_type: 'Vehicle Type',
  account_note_park_vehicle_type_3: 'Car',
  account_note_park_vehicle_type_4: 'Motorcycle',

  /* role permission */
  // role_no_permission: 'No permission',
  // role_general: 'General',
  // role_advanced: 'Advanced',
  // role_medium: 'Medium',
  // role_higher: 'Higher',
  // role_off: 'Off',
  // role_on: 'On',
  role_L0: 'L0：No permission.',
  role_new_name: 'New role(1)',
  role_topScope: 'Top scope',
  role_topScope_tooltip: 'Select a group as the visible range',
  role_topScop_default: 'Default',
  role_topScope_desc1: 'Provide the setting for resource access range.',
  role_topScope_desc2: 'Groups are in a tree structure. By setting the "visible range," the highest-level group node that adjusts the access range is determined. The current role can access the resources of that group and all its subordinate groups.',
  role_topScope_desc3: 'All derivative resources generated from non-public accounts belong to the corresponding group.',
  role_topScope_desc4: `If not specifically indicated, the default access range is set to the "Top Scope".`,
  role_topScope_L0: `Default: Account's affiliated group.`,
  role_topScope_L1: 'Designated: Specified group.',
  role_audit: 'Usage log',
  role_audit_tooltip1: 'Off：You can only query your own records',
  role_audit_tooltip2: "On：You can check other people's records",
  role_audit_desc: 'Provide access to account log records.',
  role_audit_L0: `L0：Can only query the account's own usage record.`,
  role_audit_L1: 'L1：Can access usage records.',
  // 勤務派遣
  role_dashboardPermission: 'Mission dispatch',
  role_dashboard: 'Mission dispatch',
  role_dashboard_tooltip: 'Provide access to mission dispatch function to integrate and control on-site incidents and status.',
  role_dashboard_desc: 'Provide access to mission dispatch function to integrate and control on-site incidents and status.',
  role_dashboard_L0: 'L0：No permission. Cannot access mission dispatch page.',
  role_dashboard_L1: 'L1：Allow access mission dispatch.',
  role_dashboard_L2: 'L2：Allow access public equipment list.',
  // 影像管理
  role_videoPermission: 'Image management',
  role_liveView: 'Live view',
  role_liveView_tooltip1: 'General：You can watch real-time video',
  role_liveView_tooltip2: 'Advanced：You can watch real-time video, modify titles, control PTZ and other functions',
  role_liveView_desc: 'Provide access to live video, audio, GPS and other information',
  role_liveView_L1: 'L1：Allow access live video',
  role_liveView_L2: 'L2：Allow access public live video.',
  role_video: 'Historical video',
  role_video_title: 'Provides post-event image retrieval, playback and download.',
  role_video_desc: 'Provides post-event image retrieval, playback and download.',
  role_video_L1: 'L1：Allow access historical video.',
  role_video_L2: 'L2：Allow access public historical video',
  role_videoManagement: 'Video management',
  role_videoManagement_tooltip: 'Provides historical image management functions.',
  role_videoManagement_desc: 'Provides historical image management functions.',
  role_videoManagement_L1: 'L1：Allow modify historical video descriptions, retain/delete videos.',
  // 設備操控
  role_deviceCtrlPermission: 'Device Control',
  role_deviceCtrl: 'Device Control',
  role_deviceCtrl_tooltip: 'Provides device configuration and control functions.',
  role_deviceCtrl_desc: 'Provides device configuration and control functions.',
  role_deviceCtrl_L1: 'L1：Allow modify device descriptions such as video titles.',
  role_deviceCtrl_L2: 'L2：Allow adjust camera-related settings, PTZ control, quality settings, etc.',
  role_deviceCtrl_L3: 'L3：Allow set advanced parameters for the device.',
  role_deviceAISetting: 'Recognition Task',
  role_deviceAISetting_title: 'Provides device recognition task setting function.',
  role_deviceAISetting_desc: 'Provides device recognition task setting function.',
  role_deviceAISetting_L1: 'L1：Allow access recognition task settings.',
  // 語音通話
  role_voiceCallPermission: 'Voice Call',
  role_voiceCall: 'Voice intercom',
  role_voiceCall_tooltip: 'Can use voice call function',
  role_voiceCall_desc: 'Provides access to voice intercom service, including 1-to-1 and group call modes.',
  role_voiceCall_L1: 'L1：Allow access voice intercom, including 1-to-1 and group call.',
  role_voiceCall_L2: 'L2：Allow access public address book (user/device).',
  // 車辨事件
  role_lprEventPermission: 'Vehicle Recognition Event',
  // role_lprDashboard: 'Dashboard',
  role_lprDashboard: 'Real-time license plate event',
  role_lprDashboard_tooltip1:'General：Dashboard can display match/task/round-up events',
  role_lprDashboard_tooltip2: 'Advanced：Dashboard to display all events',
  role_lprDashboard_desc: 'Access to real-time license plate recognition events.',
  role_lprDashboard_L1: 'L1：Access to real-time matching events of license plates (including vehicle information, tasks, subscriptions, etc.).',
  role_lprDashboard_L2: 'L2：Access to publicly available real-time matching events of license plates (including vehicle information, tasks, subscriptions, etc.).',
  role_lprDashboard_L3: 'L3：Access to all real-time license plate events.',
  // role_lprEventAccess: 'Event query',
  role_lprEventAccess: 'Historical license plate events - General',
  role_lprEventAccess_tooltip1: 'General events that can query a single vehicle number',
  role_lprEventAccess_desc: 'Provide query to historical license plate event functions.',
  role_lprEventAccess_L1: 'L1：Access to general queries (only for public devices).',
  role_lprEventAdvancedAccess: 'Historical license plate events - Advanced',
  role_lprEventAdvancedAccess_tooltip1: 'You can use advanced conditions to query events',
  role_lprEventAdvancedAccess_desc: 'Provide query to historical license plate event functions.',
  role_lprEventAdvancedAccess_L1: 'L1：Access to advanced queries (only for private devices).',

  // 車號管理
  role_lprPermission: 'License Plate Management',
  // role_lprSummary: 'Basic enquiries',
  role_lprSummary: 'License Plate Inquiry',
  role_lprSummary_tooltip: 'Single vehicle information can be inquired',
  role_lprSummary_desc: 'Provide license plate query function for matching databases, including vehicle information, tasks, and subscriptions.',
  role_lprSummary_L1: 'L1：Access to global license plate data inquiry.',
  role_lprSummary_L2: 'L2：Access to group vehicle data inquiry.',
  role_lprSubscription: 'Subscription management',
  role_lprSubscription_tooltip: 'Can add/query/delete subscription content',
  role_lprSubscription_desc1: 'Provide subscription license plate management function.',
  role_lprSubscription_desc2: 'Subscriptions are grouped data.',
  role_lprSubscription_desc3: `When the front-end device recognizes the subscribed license plate, there will be no alert, but the system will immediately push a message to the notification app installed on the recipient's mobile phone.`,
  role_lprSubscription_L1: 'L1：Access to manage subscription license plates.',
  role_lprMissionLv: 'Mission management',
  role_lprMissionLv_tooltip1: 'General：General tasks can be set (assistance search)',
  role_lprMissionLv_tooltip2: 'Medium：tasks below medium can be set (interrogation/assistance search)',
  role_lprMissionLv_tooltip3: 'Advanced：The following tasks can be set (arrest/interrogation/assistance)',
  role_lprMissionLv_desc1: 'Provide task vehicle number management function.',
  role_lprMissionLv_desc2: 'The task is a global data.',
  role_lprMissionLv_desc3: `When the front-end device recognizes the task license plate, it will immediately trigger an alert and display a push message requesting assistance, which will also be pushed to the notification app installed on the recipient's mobile phone.`,
  role_lprMissionLv_L1: 'L1：Access to manage (search) tasks.',
  role_lprMissionLv_L2: 'L2：Access to manage (search/check) tasks.',
  role_lprMissionLv_L3: 'L3：Access to manage (search/check/arrest) tasks.',
  role_lprMission: 'Mission type',
  role_lprMission_title: '(預留，目前未使用)',
  role_lprMission_desc: '',
  role_lprMission_L1: 'L1：Access to manage mission typess.',
  // role_lprInfoLv: 'Vehicle information management',
  role_lprInfoLv: 'Vehicle information',
  role_lprInfoLv_tooltip: 'Manageable vehicle markings and notes',
  role_lprInfoLv_desc1: 'Provide vehicle information management function.',
  role_lprInfoLv_desc2: 'Vehicle information is global data.',
  role_lprInfoLv_L1: 'L1：Access to manage global vehicle information.',
  // role_lprInfo: 'Tag list management',
  role_lprInfo: 'Vehicle Tag',
  role_lprInfo_tooltip: 'Manageable tag list',
  role_lprInfo_desc1: 'Provide vehicle tag management function.',
  role_lprInfo_desc2: 'Vehicle tags are global data and serve as classification references for vehicle information.',
  role_lprInfo_desc3: 'A single vehicle information can have multiple vehicle tags set.',
  role_lprInfo_L1: 'L1：Access to manage global vehicle tags.',
  // role_lprAnnouncement: 'Announcement management',
  role_lprAnnouncement: 'Publish Notifications',
  role_lprAnnouncement_tooltip: 'Access to manage announcement content.',
  role_lprAnnouncement_desc1: 'Manageable announcement content',
  role_lprAnnouncement_desc2: 'Publish notifications are global data.',
  role_lprAnnouncement_desc3: 'Announcement messages are pushed to the front-end devices and played once by voice, with continuous display of scrolling text messages.',
  role_lprAnnouncement_L0: 'L0：Readable global push announcement.',
  role_lprAnnouncement_L1: 'L1：Access to manage global publish notifications.',
  role_lprReport: 'Performance Reports',
  role_lprReport_tooltip: 'Provide performance report management function.',
  role_lprReport_desc1: '- Customized: Generate a performance report tailored to the selected date and device.',
  role_lprReport_desc2: '- Scheduled: Automatically generate a system performance report monthly based on the configured list of export devices.',
  role_lprReport_L1: 'L1：Accessible custom performance reports.',
  role_lprReport_L2: 'L2：Accessible system periodic performance reports and manage export devices.',
  // 物件辨識
  role_orEventPermission: 'Object Recognition',
  role_orDashboard: 'Real-time Object Events',
  role_orDashboard_desc: 'Provides real-time access to object events.',
  role_orDashboard_L1: 'L1：Can access real-time matched object events.',
  role_orDashboard_L2: 'L2：可存取公開物件即時事件。',
  role_orEventAccess: 'History Object Events',
  role_orEventAccess_desc: 'Provides access to historical object events.',
  role_orEventAccess_L1: 'L1：Can access historical object events.',
  // 人臉辨識
  role_frEventPermission: 'Facial Recognition',
  role_frDashboard: 'Real-time Person Events',
  role_frDashboard_desc: 'Provides real-time access to person events.',
  role_frDashboard_L1: 'L1：Can access real-time matched person events.',
  role_frDashboard_L2: 'L2：可存取公開人物即時比對符合事件。',
  role_frDashboard_L3: 'L3：可存取全部人物即時事件。',
  role_frEventAccess: 'Historical Person Events',
  role_frEventAccess_desc: 'Provides access to historical person events.',
  role_frEventAccess_L1: 'L1：Can access historical person events.',
  // 人物管理
  role_frPermission: 'Person Management',
  role_frInfoLv: 'Person Information',
  role_frInfoLv_desc1: 'Provides person information management functionality.',
  role_frInfoLv_desc2: 'Person information is global data.',
  role_frInfoLv_L1: 'L1：Can manage global person information.',
  role_frInfo: 'Person Tags',
  role_frInfo_desc1: 'Provides person tag management functionality.',
  role_frInfo_desc2: 'Person tags are global data and serve as a classification reference for vehicle information.',
  role_frInfo_desc3: 'Multiple person tags can be set for a single person information.',
  role_frInfo_L1: 'L1：Can manage global facial recognition tags.',
  // 帳號與權限
  role_accounrPermission: 'Account and Permissions',
  // role_account: 'Account management',
  role_account: 'User',
  role_account_tooltip: 'Manage user account related settings',
  role_account_desc1: 'Provide user account management function.',
  role_account_desc2: 'User accounts need to be verified and access levels to resources and functions are determined based on the role permissions configured.',
  role_account_L1: 'L1：View user accounts.',
  role_account_L2: 'L2：Manage user accounts.',
  role_device: 'Device',
  role_device_tooltip: 'Manage device account related settings',
  role_device_desc1: 'Provide device account management functions.',
  role_device_desc2: 'Device accounts need to be verified and the functions and resource reservation conditions provided are determined based on the authorization configured.',
  role_device_L1: 'L1：View device accounts.',
  role_device_L2: 'L2：Manage device accounts.',
  role_device_L3: 'L3：Configure device authorization.',
  role_device_L4: 'L4：Add device accounts.',
  // role_group: 'Group management',
  role_group: 'Group',
  role_group_tooltip: 'Manage group related settings',
  role_group_desc1: 'Provide group management functionality.',
  role_group_desc2: 'Groups are the basic unit of resource ownership and can be configured with users and devices, with derived resources (such as historical videos, events, logs, etc.) defaulting to the group.',
  role_group_desc3: 'Groups cannot be deleted.',
  role_group_L1: 'L1：View groups.',
  role_group_L2: 'L2：Manage groups.',
  // role_roleManagement: 'Role management',
  role_roleManagement: 'Role',
  role_roleManagement_tooltip: 'Manageable roles',
  role_roleManagement_desc1: 'Provide role management functionality.',
  role_roleManagement_desc2: 'Roles are a collection of permissions that define the access scope and functional level of various system resources.',
  role_roleManagement_desc3: 'Operators with role management permissions can create or manage roles corresponding to access scopes or permission levels.',
  role_roleManagement_desc4: 'Roles belong to the global scope and cannot have the same name.',
  role_roleManagement_L1: 'L1：View roles.',
  role_roleManagement_L2: 'L2：Manage roles.',
  // 系統與服務
  role_systemAndServicePermission: 'System and services',
  // role_system: 'System management',
  role_system: 'System settings',
  role_system_tooltip: 'Modifiable system parameters',
  role_system_desc1: 'Provide system configuration management functionality.',
  role_system_desc2: 'Used to adjust system preferences and customize settings.',
  role_system_L1: 'L1：Allow manage system settings.',

  /* Role Notify / Message */
  role_notify_add_success: `Create new role successfully`,
  role_notify_add_info: `Create a new Role：<span style="${notify_style}">{name}</span>`,
  role_notify_add_fail: `Create new role fail`,
  role_notify_edit_success: 'Role is edited',
  // role_notify_edit_info: 'Edit role successfully',
  role_notify_edit_info: `Edit role <span style="${notify_style}">{name}</span> successfully`,
  role_notify_delete_confirm: 'Do you want to delete <span>{name}</span>?',
  role_notify_delete_success_title: 'Role deleted successfully.',
  role_notify_delete_success_msg: `Deleting role: <span style="${notify_style}">{name}</span>`,
  role_message_error_name_title: 'Unable to modify name',
  role_message_error_name_content: 'This name is already in use, please enter another name.',
  role_message_error_delete_title: 'Unable to delete role',
  role_message_error_delete_prefix: 'Please transfer first',
  role_message_error_delete_suffix: 'all accounts below to other roles',
  role_message_error_delete_transfer: 'Please transfer all users below <span>{name}</span> to other roles first.',

  /* 提示 */
  alarm_hint: 'Hint',
  alarm_announcement: 'Annoucement',
  alarm_info: 'Allowing the browser to play sound effects?',

  /* Dashboard: user tree */
  tree_search: 'Search Group / Device',
  tree_online: 'Online / follow devices',
  tree_online_all: 'Online/All',
  tree_select_group: 'Select only this group',
  tree_change_name: 'Change name',
  tree_change_video_title: 'Change video title',
  tree_change_group_name: 'Change group name',
  tree_push_talk: 'Push to Talk',
  tree_select_account: 'Only this device',
  tree_voice_call: 'Voice call',
  tree_account_info: 'Device Information',
  tree_view_device: 'View device',
  tree_view_user: 'View User',
  tree_view_account: 'View Account',
  tree_video_export: 'Video Export',
  tree_processing_info: 'processing ...',
  tree_no_data: 'No data',
  tree_contacts: 'Contact List',

  /* Dashboard: video download */
  video_start_time: 'Start Time',
  video_end_time: 'End Time',
  video_type: 'Video type',
  video_length: 'Video length',
  video_size: 'Video size',
  video_date_placeholder: 'Please select a start and end time',
  video_select_all: 'Select All',
  video_unselect: 'Unselect Keep',
  video_keep_all: 'Keep All',
  video_keep_select: 'Keep selected',
  video_download_select: 'Download selected',
  video_delete_select: 'Delete selected',
  video_download: 'Download video',
  video_keep: 'Keep',
  video_none_delete: 'No videos to delete!',
  video_delete: 'Video deleted!',
  video_none_select: 'No selected videos!',
  video_set_keep: 'The video has been set to keep!',
  video_no_keep: 'The video no longer set to keep!',
  video_delete_data: 'Delete data',
  video_delete_checked: 'Are you sure you want to delete the checked data?',
  video_selected: 'Selected',
  video_total_of: 'A total of',
  video_of_records: 'records',
  video_unsupported_mime_codec: 'Unsupported mime type or codec',

  /* Video Control Button */
  video_ctrlbtn_ptz: 'Remote lens control',
  video_ctrlbtn_time_axis: 'Historical video',
  video_search_none: 'Search no historical videos!',

  /* Dashboard: event cards */
  event_tab_all: 'All',
  event_tab_match: 'Match',
  event_tab_urgent: 'Urgent',
  event_tab_video: 'Video',
  event_show_position: 'Locate account in device list',
  evnet_show_history: 'History Record',
  event_source: 'Source',
  event_result: 'Matched result',
  event_note: 'Note',
  event_recg_note: 'Recognition note',
  event_emergency: 'Emergency events',
  event_switch: 'Show all events',
  event_not_db: 'Not DB Events',
  event_chasing: 'Chased',
  event_sos: 'SOS',

  event_lpr: 'License Plate Event',
  event_fr: 'Facial Recognition Event',
  event_chased: 'Chased Event',
  event_sos_title: 'SOS Event',
  event_video: 'Video Event',
  event_or: 'Object Recognition Event',

  /* PTZ */
  ptz_move_speed: 'Moving Speed',
  ptz_zoom: 'Zoom',
  ptz_focus: 'Focus',
  ptz_preset: 'Preset List',
  ptz_preset_placeholder: 'New Preset',
  ptz_preset_setting_success: 'Preset setting successfully!',
  ptz_preset_setting_fail: 'Preset setting failed!',
  ptz_vertical_mirror: 'Vertical Mirror',
  ptz_horizontal_mirror: 'Horizontal Mirror',
  ptz_infrared: 'Infrared',
  ptz_high_sensitivity: 'High Sensitivity',
  ptz_tooltip_zoom_out: 'Zoom Out',
  ptz_tooltip_zoom_in: 'Zoom In',
  ptz_tooltip_open: 'Open',
  ptz_tooltip_close: 'Close',
  ptz_tooltip_auto: 'Auto',
  ptz_tooltip_auto_focus: 'Auto Focus',
  ptz_tooltip_near_focus: 'Near Focus',
  ptz_tooltip_far_focus: 'Far Focus',

  /* 設備 */
  device_reboot: 'Reboot',
  device_reboot_confirm: 'Are you sure want to reboot the device "{account}"？',
  device_hub_reset: 'Reset Hub',
  device_hub_reset_confirm: 'Are you sure want to reset the hub "{account}"？',

  /* History: Search Bar */
  search_tab_history: 'Basic Search',
  search_tab_advanced_search: 'Adv. Search',
  search_cause: 'Reason for action',
  search_cause_placehold: 'Investigating John Doe drug case',
  search_plate_number_required: 'Plate Number',
  search_time_interval: 'Time Interval',
  search_select_date: 'Select Date',
  search_select_time: 'Select Time',
  search_time_placehold: 'Please select a start and end time',
  search_hint: 'Hint',
  search_hint_reason: 'Please enter the reason',
  search_hint_reason_length: 'The reason for the case must be less than or equal to 30 characters',
  search_hint_fill_number: 'Please fill in the "plate number"',
  search_hint_fill_device: 'Please select the "device"',
  search_hint_fill_time: 'Please select the "time interval"',
  search_hint_nodata: 'No data',
  search_export: 'Export',
  search_device: 'Device',
  search_device_required: 'Device',
  search_device_placehold: 'Please Choose',
  search_device_unit: 'Department',
  search_plate_number: 'Plate Number',
  search_car_type: 'Car Type',
  search_car: 'Car',
  search_motocycle: 'Motocycle',
  search_event_category: 'Event Category',
  search_please_choose: 'Please Choose',
  search_all: 'All',
  search_subscription: 'Subscription',
  search_mission: 'Mission',
  search_mark: 'Tag',
  search_round_up: 'Chased',
  search_not_specified: 'Not Specified',
  search_misjudgment: 'Misjudgment',
  search_non_misjudgment: 'Non-misjudgment',
  search_cofind: 'Co-find',
  search_check: 'Check',
  search_arrest: 'Arrest',
  search_vehicle_marking: 'Vehicle Tag',
  search_finished_status: 'Finished Status',
  search_finish: 'Finish',
  search_undone: 'Undone',
  search_total_of: 'Total',
  search_of_events: '',
  search_timeout_message: 'Too much content, please narrow your query',
  search_clear: 'Clear',
  search_adv_filter: 'Advanced Conditions',
  search_channel_id: 'Camera',
  search_matched: 'Matched',
  search_inference_matched: 'Inference Matched',
  search_no_match: 'No Match',
  search_cause_default_park: 'Historical query',

  /* History: History Events */
  history_event: 'History Events',
  history_event_lpr: 'License Plate',
  history_event_fr: 'Person',
  history_event_or: 'Object',
  history_event_previous: 'Previous',
  history_event_next: 'Next',
  history_event_wheelzoom: 'Wheel Zoom',
  history_event_previous_tooltip: 'Previous Event (left key on keyboard)',
  history_event_next_tooltip: 'Next Event (right key on keyboard)',
  history_event_back_tooltip: 'Back to previous page (Esc)',
  history_event_map_goto: 'Open Google map',
  history_query_by_event: 'Query by event',
  history_device: 'Device',
  history_employee: 'Employee',
  history_recognition: 'Recognition',
  history_tag: 'Tag',
  history_mission: 'Mission',
  history_mission_note: 'Mission note',
  history_channel: 'Channel',
  history_event_prev_page: 'Previous Page',
  history_event_next_page: 'Next Page',
  history_event_no: 'No.',
  history_event_pages: 'page',
  history_event_screen_capture: 'Screen Capture',
  history_event_screen_capture_info: 'The captured size does not match the screen size. Please reload the webpage and take a screenshot again.',
  history_show_snapshot: 'Show Snapshot',
  history_show_video: 'Show Video',

  /* History: 人物 */
  history_fr_name: 'Name',
  history_fr_id: 'ID',
  history_fr_age: 'Age',
  history_fr_tag: 'Tag',
  history_fr_note: 'Character note',
  history_fr_group: 'Group',
  history_fr_device: 'Device',
  history_fr_gps: 'GPS',
  history_fr_open_google_map: 'Open Google map',
  history_fr_event_category: 'Event Category',
  history_fr_score: 'Recognition score',
  history_fr_cv: 'CV',
  history_fr_tag_suspected: 'suspected target',

  /* History: 緊急 */
  history_device_group: 'Group',
  history_link_user: 'Linked user',
  history_user_group: 'User group',

  /* QR Code Panel */
  qr_title: 'Download APP',

  /* Dashboard: tooltips */
  previous_video: 'Previous Video',
  next_video: 'Next Video',
  play: 'Play',
  pause: 'Pause',
  mute: 'Mute',
  unmute: 'Unmute',
  play_rate: 'Playback Rate',
  zoom_in: 'Zoom in',
  zoom_out: 'Zoom out',
  zoom_ratio: 'Zoom Ratio',
  show_only_events: 'Show only events for this device',
  download_video: 'Video download',
  full_screen: 'Full screen',
  jump_to_live: 'To live screen',
  back_event: 'Back to event',
  the_day_before: 'The day before',
  the_day_after: 'The day after',
  a_minute_ago: 'A minute ago',
  one_minute_after: 'One minute after',
  zoom_out_the_play: 'Zoom out the playback window',
  enlarge_the_play: 'Zoom in the playback window',
  mode_1x1: '1x1 mode',
  mode_2x2: '2x2 mode',
  mode_3x3: '3x3 mode',
  mode_4x4: '4x4 mode',
  reorder_device_list: 'Reorder',
  collapse_the_play: 'Collapse the playback window',
  expand_the_play: 'Expand the playback window',
  expand_event_list: 'Expand event list',
  collapse_event_list: 'Collapse event list',
  collapse_device_list: 'Collapse the device list',
  expand_device_list: 'Expand the device list',
  more: 'More',
  less: 'Less',
  automatic_logout: 'Prevent automatic logout when idle',
  no_selected_user: 'No selected device',
  no_streaming: 'No Streaming Available',
  no_permission_all: 'No permission to watch all real-time video and hisotrical videos.',
  no_permission_live_view: 'No permission to watch real-time audio and video.',
  no_permission_video: 'No permission to watch historical videos.',
  no_live_stream: 'No live stream',
  no_video: 'No video available',
  retry_query: 'Retry query',
  video_pause: 'Video paused',
  mode_nxn_switch_hint: 'Please drop to device window for switch!',

  /* Account Management */
  am_title: 'Account and Permission',
  am_user: 'User',
  am_device: 'Device',
  am_group: 'Group',
  am_role: 'Role',
  am_search_account: 'Search account/account name',
  am_role_label: 'Role',
  am_edit_not_complete: 'Edit not complete',
  am_cancel_edit: 'Are you sure you want to cancel this edit?',
  am_map_search_hint: 'Search road or GPS',
  am_map_reset: 'Reset Coordinate',
  am_user_create: 'Create User',
  am_user_create_confirm: '使用者新增後將無法刪除, 您確定要新增嗎？',

  /* Account Management-device */
  am_search_device: 'Search device/device name/video title',
  am_device_name: 'Name',
  am_device_video_name: 'Video Title',
  am_device_event_keep: 'Event Keep',
  am_device_video_keep: 'Video Keep',
  am_group_tree_search: 'Search group',
  am_device_keep_time: 'Keep Time',
  am_device_keep_time_tip: 'System default keep time.',
  am_device_keep_time_edit: 'Edit Keep Time',
  am_device_create: 'Create Device',
  am_device_create_confirm: 'Once devices are added, they cannot be deleted. Are you sure you want to add them?',
  am_device_type_default: 'General',
  am_device_type_bwc: 'Bodycam',
  am_device_type_patrol: 'Patrol',
  am_device_type_phone: 'Phone',
  am_device_type_ptz: 'PTZ Dome Camera',
  am_device_type_box: 'PTZ Box Camera',

  /* Account Management-group */
  am_group_tab_group: 'Group',
  am_group_tab_quota: 'Authorize',
  am_search_group: 'Search group',
  am_group_count: 'Groups：{count}',
  am_group_online_account: 'Online Account',
  am_group_name_top: 'Please input {min}-{max} characters',
  am_group_title: 'Group',
  am_view_quota_title: 'View Quota',
  am_live_quota_title: 'Distribute Quota',
  am_group_quota_out: 'View',
  am_group_quota_in: 'Publish',
  an_group_quota_filter: 'Authorization Status',
  an_group_quota_filter_0: 'Empty', // 無授權
  an_group_quota_filter_1: 'Not Full', // 授權未滿
  an_group_quota_filter_2: 'Full', // 授權已滿
  am_quota_desc: 'Used / Distributed / ALL',
  am_quota_edit: 'Edit Authorize',
  am_quota_editing: 'Editing',
  am_quota_edit_confirm: 'Are you sure you want to modify the authorization?',
  am_quota_using_value: 'Using',
  am_quota_divided_value: 'Assigned',
  am_quota_max_value: 'Authorization',
  am_quota_parent_group_auth_empty: 'Parent group {name} has no authorization',
  am_quota_parent_group_auth_full: `Authorization of Parent group {name} has been full`,
  am_quota_auth_not_enough: 'Authorization of {name} not enough',
  am_quota_auth_full: 'All authorization of {name} has been allocated and cannot be deleted',
  am_quota_error_group_has_account: '{name} group has account, we cannot move the parent group',
  am_quota_error_group_has_both_quota: '{name} group has publish and view quota, we cannot move the parent group',
  am_quota_error_group_has_in_quota: '{name} group has publish quota, we cannot move the parent group',
  am_quota_error_group_has_out_quota: '{name} group has view quota, we cannot move the parent group',
  am_add_group: 'Add Group',
  am_add_subgroup: 'Create subgroup',
  am_parent_group_empty: 'Belonged group name',
  am_parent_group: 'Belonged group',
  am_parent_group_hint: 'Belonged group',
  am_group_name: 'Group Name',
  am_group_name_hint: 'New Group(1)',
  am_group_name_tip: 'Please input {min}-{max} characters',
  am_group_name_duplicate: 'This group name has existed',
  am_group_id: 'ID',
  am_group_updated_time: 'Updated Time',
  am_group_create_time: 'Created Time',
  am_add_group_fail: 'Create group fail.',
  am_add_group_pass: 'Group successfully created.',
  am_edit_group_fail: 'Update group fail',
  am_edit_group_pass: 'Group successfully updated.',
  am_quota_short_hint1: 'Group',
  am_quota_short_hint2: 'quota short',
  am_group_create_confirm: 'Group cannot be deleted once added. Are you sure you want to add it?',
  am_group_department_code: 'Department Code',
  am_group_staff_info: 'Information',
  am_group_watching: 'Watching',
  am_group_account_user: 'User',
  am_group_account_device: 'Device',
  am_group_quota_using: 'Using',
  am_group_quota_divided: 'Divided',
  am_group_quota_total: 'Total',
  am_group_dev_info_snapshot_link_tooltop: 'Snapshot Link',
  am_group_dev_info_streaming_link_tooltop: 'RTSP Link',
  am_group_dev_info_name: 'Name',
  am_group_dev_info_info_name: 'Device Name',
  am_group_dev_info_video_title: 'Video Title',
  am_group_dev_info_property: 'Property',
  am_group_dev_info_device_id: 'Device SN',
  am_group_dev_info_device_model_id: 'Device Model',
  am_group_dev_info_device_info_type: 'Device Type',
  am_group_dev_info_latest_action: 'Latest action',
  am_group_dev_info_gps: 'GPS',
  am_group_dev_info_gps_timestamp: 'Time',
  am_group_duplicate_name: 'This group name has existed',
  am_group_edit_notify: 'Modified group',
  am_group_edit_confirm: 'Are you sure to modify group?',
  am_group_add_notify: 'Created group',

  /* Account Management-role */
  am_role_helper_title: 'Role Management',
  am_role_helper_intro: 'Role is a collection of permissions designed to define the access scope and functional level of various system resources. Roles can be applied to user accounts to regulate the permission levels that the account can operate.',
  am_role_helper_desc1: 'Higher levels include access scope and functional items of lower levels.',
  am_role_helper_desc1_sub1: 'Access Scope (Scope): Levels range from low to high, with larger scope as the level increases.',
  am_role_helper_desc1_sub2: 'Functional Level (Level): Levels range from low to high, with more permissions as the level increases.',
  am_role_helper_desc2: 'Roles belong to the global scope and cannot have the same name.',

  /* System Setting */
  system_global_setting: 'Global setting',
  system_custom_web: 'Custom web',
  system_backend_setting: 'Backend Setting',
  system_server_capability: 'Server Capability',
  system_aibox: 'AiBox Management',
  system_custom_system_name: 'System name',
  system_custom_idle_logout_time: 'Idle logout time',
  system_custom_logout_not: 'Do not logout',
  system_custom_app_download: 'Show APP download link',
  system_custom_manual_download: 'Show user manual loading point',
  system_custom_customize: 'Customer',
  system_custom_favicon: 'Tab favicon 32*32',
  system_custom_banner: 'Expanded banner 130*56',
  system_custom_logo: 'Collapse icon 56*56',
  system_custom_upload: 'Upload',
  system_custom_restore: 'Restore default',
  system_custom_restore_info: 'Restore the above icons setting to default values',
  system_custom_web_customize_func: 'Customize Functions',
  system_custom_web_customize_func_park: 'Parking',

  /* System Setting - AiBoxMgr */
  setting_aibox_top_box: 'AiBox',
  setting_aibox_top_ip: 'IP',
  setting_aibox_top_count: 'Count',
  setting_aibox_top_abnormal_count: 'Abnormal',
  setting_aibox_page_start: 'Count',
  setting_aibox_page_end: 'record(s)',
  setting_aibox_page_total: 'Search Result',
  setting_aibox_total_of: 'DB has',
  setting_aibox_of_records: 'record(s)',
  setting_aibox_goto_page: 'Go to page',
  setting_aibox_state_normal: 'Normal',
  setting_aibox_state_abnormal: 'Abnormal',
  setting_aibox_table_name: 'AiBox',
  setting_aibox_table_ip: 'IP',
  setting_aibox_table_udid: 'UDID',
  setting_aibox_table_status: 'Task',
  // setting_aibox_table_temp: 'Temperature',
  setting_aibox_table_cpu: 'CPU',
  setting_aibox_table_gpu: 'GPU',
  setting_aibox_table_mem: 'MEM',
  setting_aibox_table_hd: 'HDD',
  setting_aibox_table_recognition_src: 'Recognition',
  setting_aibox_table_updated_time: 'Updated Time',
  setting_aibox_delete_title: 'Delete AiBox',
  setting_aibox_task_device: 'Device',
  setting_aibox_type_or: 'Object Recognition',
  setting_aibox_type_fr: 'Person Recognition',
  setting_aibox_type_lpr: 'License Plate Recognition',
  setting_aibox_task_recognition_type: 'Recognition Category',
  setting_aibox_task_recognition_state: 'Recognition Status',
  setting_aibox_not_connected: 'AiBox is not connected',
  setting_aibox_task_status_0: 'Enabled',
  setting_aibox_task_status_1: 'Recognizing',
  setting_aibox_task_status_10: 'Device Not Published',
  setting_aibox_task_status_11: 'Streaming Connecting',
  setting_aibox_task_status_12: 'Insufficient Authorization',
  setting_aibox_task_status_13: 'System Error',
  setting_aibox_task_status_14: 'Configuration Profile Version Not Supported',
  setting_aibox_task_status_15: 'Recognition Function Not Supported',
  setting_aibox_task_status_16: 'Parameter content does not match',
  setting_aibox_task_recognition_or: 'Object',
  setting_aibox_task_recognition_lpr: 'Car',
  setting_aibox_delete_aibox: 'Do you want to delete the AiBox?',
  setting_aibox_delete_aibox_hint: 'There are still AiBox tasks and cannot be deleted',
  setting_aibox_delete_notify_title: 'Remove AiBox',
  setting_aibox_delete_pass: 'Remove AiBox success',
  setting_aibox_delete_fail: 'Remove AiBox failed',
  setting_aibox_delete_aibox_task: 'Do you want to delete the AiBox task?',
  setting_aibox_task_delete_notify_title: 'Remove AiBox task',
  setting_aibox_task_delete_pass: 'Remove AiBox task success',
  setting_aibox_task_delete_fail: 'Remove AiBox task failed',

  /* AI Box */
  ai_recognition_setting: 'Recognition Setting',
  ai_device_recognition: 'Device Recognition',
  ai_person_recognition: 'Person Recognition',
  ai_could_recognition: 'Cloud Recognition',
  ai_object_recognition: 'Object Recognition',
  ai_object: 'Object',
  ai_license_plate_recognition: 'License Plate Recognition',
  ai_license_plate: 'License Plate',
  ai_updated_time: 'Updated',
  ai_recognition: 'Recognition',
  ai_notify: 'Notify',
  ai_notify_setting: 'Notify Setting',
  ai_box: 'AI Box',
  ai_task_add: 'Adding',
  ai_task_edit: 'Editing',
  ai_task_delete: 'deleting',
  ai_status_0: 'Enabled',
  ai_status_1: 'Recognizing',
  ai_status_10: 'Device not released',
  ai_status_11: 'streaming connection',
  ai_status_12: 'Insufficient number of authorizations',
  ai_status_13: 'systematic error',
  ai_status_14: 'Profile version not supported',
  ai_status_15: 'Recognition function is not supported',
  ai_status_16: 'Parameter content mismatch',
  ai_try_connect: 'Try to connect to stream',
  ai_select_aibox: 'Please select AI box',
  ai_temperature: 'Temperature',
  ai_memory: 'MEM',
  ai_hard_disk: 'HDD',
  ai_resources: 'Recognition resources',
  ai_other_resources: 'Other resources',
  ai_add_notification_task: 'Add a notification task',
  ai_image_preview: 'Image preview',
  ai_recg_fps_level: 'FPS level',
  ai_repeat_interval: 'Repeat interval',
  ai_stay_sec: 'Stay seconds',
  ai_category: 'Category',
  ai_subscriber: 'Subscriber',
  ai_group_name_placeholder: 'Group or name',
  ai_show_checked: 'Show checked',
  ai_edit_receiver: 'Edit subscriber',
  ai_mode: 'Mode',
  ai_object_detect: 'Object detect',
  ai_motion_detect: 'Motion detect',
  ai_detect_target_threshold: 'Detection target threshold',
  ai_motion_level: 'Motion level',
  ai_advanced_settings: 'Advanced settings',
  ai_person_level: 'Person',
  ai_car_level: 'Car',
  ai_bike_level: 'Motor',
  ai_truck_level: 'Truck',
  ai_bus_level: 'Bus',
  ai_warning_area: 'Warning area',
  ai_delete_data: 'Delete data',
  ai_device: 'Device',
  ai_recognition_category: 'Recognition category',
  ai_recognition_status: 'Recognition status',
  ai_level_0: 'Close',
  ai_level_1: 'Lowest',
  ai_level_2: 'Low',
  ai_level_3: 'Medium',
  ai_level_4: 'High',
  ai_level_5: 'Highest',
  ai_motion_level_1: 'Low',
  ai_motion_level_2: 'Medium',
  ai_motion_level_3: 'High',
  ai_insufficient_recognition_resources: 'Insufficient recognition resources.',
  ai_no_boxes_available: 'Sorry, there are no identification boxes available.',
  ai_edit_not_complete: 'Edit not complete',
  ai_cancel_edit: 'Are you sure you want to cancel this edit?',
  ai_parameter_adjustment: 'Parameter adjustment',
  ai_event_score_greater_than: 'Event trigger score must be greater than or equal to the event candidate score.',
  ai_candidate_score_adjust: 'Event candidate score adjusted to {score}',
  ai_event_score_adjust: 'Event trigger score adjusted to {score}',
  ai_area1: 'Block 1',
  ai_area2: 'Block 2',
  ai_area3: 'Block 3',
  ai_new_recognition: 'New recognition task',
  ai_recog_task_add_successfully: 'Recognition task added successfully',
  ai_edit_recognition: 'Edit recognition task',
  ai_recog_task_edit_successfully: 'Recognition task edited successfully',
  ai_task_already_added: 'There has been a new recognition task. Please complete the settings before adding another one.',
  ai_roi_set: 'Adjust ROI range',
  ai_roi_lock: 'Lock ROI range',
  ai_tooltip_roi_reset: 'Return to default range',
  ai_tooltip_mode: '<strong style="color: #FFE99F;">Object Detection</strong>: Send a notification after detecting the checked object.<br/><strong style="color: #FFE99F;">Motion detection</strong>: Send a notification after detecting a moving object in the screen.',
  ai_tooltip_or: 'Object Detection: Send a notification after detecting the checked object.',
  ai_tooltip_md: 'Motion detection: Send a notification after detecting a moving object in the screen.',
  ai_tooltip_threshold: 'The lower the threshold, the more objects on the screen can be found, but there is also the possibility of reducing the accuracy.',
  ai_tooltip_motion: 'The higher the sensitivity, the more sensitive to moving changes in the screen.',
  ai_tooltip_recg_fps_level: 'The recognition frequency per second, it is recommended that the faster the object moves, the higher the recognition frequency setting.',
  ai_tooltip_repeat_interval: 'When the event occurs repeatedly, the notification will be sent again and the event will be uploaded after the interval of this set time.',
  ai_tooltip_stay_sec: 'How long does the object stay in the warning zone before reporting.',
  ai_tooltip_warning_area: 'A polygon with a maximum of 8 points, when the object touches the warning zone, a notification will be issued.',

  /* AI Box - recognition setting */
  ai_tag: 'Tag',
  ai_note: 'Note',
  ai_editor: 'Editor',
  ai_expert_mode: 'Expert mode',
  ai_full_face_mode: 'Full face mode',
  ai_half_face_mode: 'Half face mode',
  ai_image_source: 'Image source',
  ai_external_cam: 'External cam',
  ai_internal_cam: 'Internal cam',
  ai_image_source_tip: 'Set the recognized image source',
  ai_param_source: 'Parameter source',
  ai_global_config: 'System default',
  ai_user_config: 'Device custom',
  ai_param_source_tip: '<strong style="color: #FFE99F;">System Default</strong>: The default recognition parameter values set by the system, which cannot be modified. <br/><strong style="color: #FFE99F;">Device Customization</strong>: Depending on the device usage requirements, the recognition parameter values can be customized.',
  ai_enable: 'Enable',
  ai_enable_tip: 'Face recognition functionality can be enabled or disabled.',
  ai_model_country: 'Applicable country',
  ai_model_country_tip: 'Select the country to support license plate recognition',
  ai_face_size_ratio: 'Min face',
  ai_face_size_ratio_tip: 'The minimum detected face size as a percentage of the screen.',
  ai_detect_mode: 'Detect mode',
  ai_precise_detect: 'Precise detect',
  ai_quick_detect: 'Quick detect',
  ai_detect_mode_tip: '<strong style="color: #FFE99F;">Precise Matching</strong>: Can output results with higher accuracy; <br/><strong style="color: #FFE99F;">Fast Matching</strong>: Can quickly output recognition results.',
  ai_trigger_score: 'Event score',
  ai_candidate_score: 'Candidate score',
  ai_full_face_trigger_score_tip: '全臉模式下相似度的閾值. 閾值越低, 會有較多的比對結果, 但同時也會有降低精確度的可能性',
  ai_full_face_candidate_score_tip: 'Similarity threshold in full face mode. The lower the threshold, the more matching results there will be, but it may also potentially reduce accuracy.',
  ai_mask_trigger_score_tip: 'Similarity threshold in mask mode. The lower the threshold, the more matching results there will be, but it may also potentially reduce accuracy.',
  ai_mask_candidate_score_tip: 'Threshold for candidates in mask mode. The lower the threshold, the more candidates there will be for reference.',
  ai_detect_threshold_level: 'Face detection threshold',
  ai_detect_threshold_level_tip: 'Face detection threshold. The lower the threshold, the more faces can be detected in the image, but it may also potentially reduce accuracy.',
  ai_event_post_mode: 'Event upload mode',
  ai_event_post_mode_tip: '<strong style="color: #FFE99F;">Matched Only</strong>: Only events that match will be uploaded; <br/><strong style="color: #FFE99F;">All Recognized</strong>: All recognition results will be uploaded.',
  ai_event_match: 'Event match',
  ai_event_all: 'All',
  ai_notify_filter_mode: 'Event notify mode',
  ai_notify_filter_mode_tip: '<strong style="color: #FFE99F;">Matched Only</strong>: Only events that match will be reported; <br/><strong style="color: #FFE99F;">All Recognized</strong>: All events will be reported. <br/><strong style="color: #FFE99F;">Not Matched</strong>: Only events that do not match will be reported. <br/><strong style="color: #FFE99F;">Not reported</strong>: No events will be reported.',
  ai_notify_match: 'Event match',
  ai_notify_all: 'All',
  ai_notify_not_match: 'Not match',
  ai_notify_none: 'Not notify',
  ai_event_duration: 'Event repeat interval',
  ai_noise_event_duration: 'Noise event duration',
  ai_event_duration_tip: 'When an event recurs, an upload event will be triggered again only after this set time interval has elapsed.',
  ai_noise_event_duration_tip: 'When the noisy vehicle event occurs repeatedly, the upload event will be sent again after this set time interval.',
  ai_repost_data_store_limit: 'Repost event retention limit',
  ai_repost_data_store_limit_tip: 'Number of events retained by the device when unable to upload events.',
  ai_lpr_frequency: 'Recognition frequency',
  ai_lpr_frequency_tip: 'The number of frames recognized per second',
  ai_lpr_size_ratio: 'Min plate',
  ai_lpr_size_ratio_tip: 'The detected minimum license plate size as a percentage of the screen',
  ai_lpr_detect_mode: 'Comparison mode',
  ai_noise_car_decibel_threshold: 'Noise decibel threshold',
  ai_noise_car_decibel_threshold_tip: 'When the decibel level reaches the threshold, a noisy vehicle event is triggered.',
  ai_noise_car_event_duration_tip: 'When the decibel level continuously meets the threshold, how often is the NoiseCar event sent',
  ai_decibel_offset: 'Offset',
  ai_decibel_offset_tip: 'Decibel Value Compensation',
  ai_confirm_count: 'Confirm count',
  ai_confirm_count_tip: 'The larger the value, the less likely it is to output the result, but the accuracy of the output result will be higher',
  ai_accept_diff_num: 'Acceptable number difference',
  ai_accept_diff_num_tip: 'The number of digits to correct the recognition result',
  ai_indent: 'Recoginition boundary',
  ai_indent_tip: 'If the detected license plate exceeds this boundary, the recognition result will not be output',
  ai_is_complete_mode: 'Complete mode',
  ai_is_complete_mode_tip: 'If enabled, only complete identification results will be output. Otherwise, only identification results will be output',
  ai_roi: 'Adjust recognition range.',
  ai_roi_tip: 'You can drag the borders to adjust the recognition range.',
  ai_event_unit: 'entry',
  ai_low: 'Low',
  ai_medium: 'Medium',
  ai_high: 'High',
  ai_setting_success: 'Settings saved successfully.',
  ai_setting_fail: 'Settings failed',

  /* BoviLive notify */
  bl_subscriber_checked: 'Checked ({count})',
  bl_notice_me: 'Notice me',

  /* LINE notify */
  line_notify: 'LINE notify',
  line_notify_add: 'Add notify',
  line_description: 'Description',
  line_group_link: 'Link LINE Group',
  line_link_method: 'Please select a linking method：',
  line_link_url: 'Open URL',
  line_link_url_hint: 'Open URL',
  line_link_qr: 'Scan QR code',
  line_link_qr_hint: 'Scan QR code',
  line_link_code: 'Enter token',
  line_link_code_hint: 'Enter verification code',
  line_link_token: 'Token',
  line_link_token_hint: 'The length of the wand exceeds the limit, please re-enter',
  line_group_choose: 'Linked to LINE',
  line_open_browser: 'Open browser',
  line_open_system_browser: 'Open system browser',
  line_linking_code: 'Code',
  line_link: 'Link',
  line_linking_hint: 'Not yet linked, please complete the linking process.',
  line_link_success: 'Linking successful',
  line_link_fail: 'Linking failed',
  line_link_status: 'Link status',
  line_link_status_yes: 'Linked',
  line_link_status_no: 'Not linked',
  line_unlink: 'Unlink',
  line_unlink_confirm: 'Are you sure you want to unlink?',
  line_notify_remove: 'Confirm deleting this notification setting?',
  line_message_count: 'Message count',
  line_message_reset: 'Reset Time',
  line_notify_test: 'Send test notification',
  line_notify_test_success: 'Test notification sent successfully',
  line_edit: 'Edit',
  line_edit_success: 'Edit successful',
  line_note_char_warn: 'Note cannot exceed {count} charcters',
  line_description_char_warn: 'Description cannot exceed {count} charcters',
  line_add_success: 'Add successful',
  line_add_fail: 'Add failed',
  line_tip_title: 'Linked process',
  line_tip_url_1: 'Enter your account password and click "Login"',
  line_tip_url_2: 'After selecting the group to be notified, click "Agree and Link"',
  line_tip_url_3: 'When "Linkage with "Bovia" completed" is displayed on LINE Notify, the setting is completed.',
  line_tip_token_1: 'Log in to the LINE Notify page',
  line_tip_token_2: 'Click "Personal Page"',
  line_tip_token_3: 'Click "Issue Token"',
  line_tip_token_4: 'After filling in the name of the token and selecting the group to be notified, click "Issue"',
  line_tip_token_5: 'Copy the token',
  line_tip_token_6: 'Copy the scepter to the Bovia settings page and click "Bind"',

  line_tip_url_1: 'Create a LINE group in advance and invite LINE Notify to the group',
  line_tip_url_2: 'Click "Link with LINE"',
  line_tip_url_3: 'After entering the account password, click "Login"',
  line_tip_url_4: 'After selecting the group to be notified, click "Agree and Link"',
  line_tip_url_5: 'When the newly added related information is displayed in the LINE group, the setting is completed',
  line_tip_qr_2: 'Scan QR Code with mobile phone',
  line_tip_token_1: 'Create a LINE group in advance and invite LINE Notify to the group',
  line_tip_token_2: 'Log in to LINE Notify page',
  line_tip_token_3: 'Click "Personal Page"',
  line_tip_token_4: 'Click "Issue Token"',
  line_tip_token_5: 'After filling in the token name and selecting the group to be notified, click "Issue"',
  line_tip_token_6: 'Copy token',
  line_tip_token_7: 'Copy the token to the Bovia settings page and click "Link"',
  line_tip_token_8: 'When the newly added related information is displayed in the LINE group, the setting is completed',

  /* AI Box - LPR */
  ai_lpr_tag: 'LPR Tags',

  /* 人辨資料庫 */
  fr_title: 'Person manage',
  fr_info: 'Person Information',
  fr_filter_name: 'Name',
  fr_filter_name_hint: 'John Doe', // 無名氏： John Doe/Jane Doe
  fr_filter_tag: 'Tags',
  fr_filter_time: 'Update Time',
  fr_filter_id: 'ID',
  fr_filter_id_hint: 'Z123456789',
  // fr_filter_feature: 'Feature',
  fr_filter_enabled: 'Person Info',
  fr_filter_tooltip: 'Advanced Conditions', // 進階條件
  frInfo_idCard_filter: 'ID',
  frInfo_feature_filter: 'Register Photo',
  frInfo_feature_filter_0: 'All',
  frInfo_feature_filter_1: 'Any Enabled',
  frInfo_feature_filter_2: 'Any Disabled',
  frInfo_feature_filter_3: 'All Disabled', // 'No Enabled'
  frInfo_feature_filter_4: 'Never register', // 沒用過
  frInfo_enabled_filter: 'Person Status',
  frInfo_enabled_filter_all: 'All',
  frInfo_enabled_filter_0: 'Disabled',
  frInfo_enabled_filter_1: 'Enabled',
  fr_new_fr_tooltip: 'Create Person',
  fr_db_info_tooltip: 'Database Information',
  fr_tag_mgr_tooltip: 'Tag Manage',

  fr_tag_mgr_title: 'Tag Manage',
  fr_tag_add: 'Create Person Tag',
  fr_tag_add_placeholder: 'Create Tag',
  fr_tag_duplicate_hint: 'The tag has existed, please input again',
  fr_tag_total: '{total} tag(s)',
  fr_tag_total_count: '{count}/{total} tag(s)',
  fr_tag_add_hint: 'Unable to delete tags after adding. Are you sure you want to proceed with the addition?',

  fr_db_info_title: 'Database Information',
  fr_db_info_data_cnt: '{count}筆',
  fr_db_info_data_latest_sn: '最新序號：',
  fr_db_info_data_update_time: '更新時間：',
  fr_db_info_db: '資料庫',
  fr_db_info_data_fr_cnt: '人物筆數：',
  fr_db_info_feature: '特徵值',
  fr_db_info_data_used: '已使用{percent}%',
  fr_db_info_data_reg_cnt: '註冊筆數：',
  fr_db_info_data_quota: '授權配額：',
  fr_db_info_db_package: '資料庫封裝',
  fr_db_info_data_version: 'Version：',

  fr_table_register: 'Register',
  fr_table_photo: 'Avatar', //頭像
  fr_table_name: 'Name',
  fr_table_id: 'ID',
  fr_table_tag: 'Tag',
  fr_table_update_time: 'Updated Time',
  fr_table_create_time: 'Created Time',
  fr_table_empty_hint: 'Please input query conditions',
  fr_table_searching: 'Searching',
  fr_table_delete_pass: 'Delete Succeed',
  fr_table_delete_fail: 'Delete Failed',

  fr_create_id_duplicate: 'The ID already exists',
  fr_info_data_title: 'Person Info',
  fr_info_data_delete_confirm: 'Are you sure you want to delete all information for {name}?',
  fr_info_data_enable: 'Enable',
  fr_info_data_disable: 'Disable',
  fr_info_data_name: 'Name',
  fr_info_data_gender: 'Gender',
  fr_info_data_id: 'ID',
  fr_info_data_check_id: 'Check ID',
  fr_info_data_age: 'Age',
  fr_info_data_age_edit: 'Birthday',
  fr_info_data_tag: 'Tag',
  fr_info_data_note: 'Note',
  fr_more: 'More',
  fr_less: 'Less',
  fr_info_data_phone: 'Phone',
  fr_info_data_birth_place: 'Birth Place',
  fr_info_data_city: 'City',
  fr_info_data_address: 'Address',
  fr_info_data_job: 'Job',
  fr_info_photo_title: 'Register Photo',
  fr_info_photo_registered: 'Register',
  fr_info_photo_registered_title: 'Registered Photo(s)',
  fr_info_photo_unregistered_title: 'Unregistered Photo(s)',
  fr_info_photo_registered_hint: 'Photo fulled',
  fr_info_photo_registering: 'Registering...',
  fr_info_photo_add: 'Add Photo',
  fr_info_other_title: 'Data',
  fr_info_other_src: 'Source', // 建立來源
  fr_info_other_src_0: 'User created',
  fr_info_other_src_1: 'Imported',
  fr_info_other_update_time: 'Updated Time',
  fr_info_other_create_time: 'Created Time',
  fr_info_data_required: 'Required',
  fr_info_data_phone_hint: 'Allow bumber、+、-、()',
  fr_info_data_gender_0: 'Other',
  fr_info_data_gender_1: 'Male',
  fr_info_data_gender_2: 'Female',
  fr_info_data_gender_9: 'Unknown',
  fr_info_date_fetch: 'Get FR information',
  fr_info_data_close_hint_title: 'Warn',
  fr_info_data_close_hint_msg: 'Are you sure you want to abandon the changes?',
  fr_feature_computing: 'Computing...',
  fr_feature_delete_confirm: 'Are you sure you want to delete the photo?',
  fr_feature_1: 'Invalid',
  fr_feature_2: 'Not available',
  fr_feature_3: 'Poor',
  fr_feature_4: 'Available',
  fr_feature_5: 'Best',
  fr_feature_invalid_hint: 'Invalid feature score, cannot be registered',
  fr_face_0: 'Facial feature', // '全臉特徵值',
  fr_face_1: 'Half-face feature', // '半臉特徵值',
  fr_face_score: 'Feature Score {score}',
  fr_face_0_score: 'Facial feature {score}',
  fr_face_1_score: 'Half-face feature {score}',
  fr_action_set_avatar: 'Set avatar',
  fr_action_download_photo: 'Download Photo',
  fr_action_delete_feature: 'Delete Photo',
  fr_no_birthday: "（Year／Month／Day）",

  fr_photo_new: 'Add Photo',
  fr_photo_available_photo: 'Available Photo(s)：',
  fr_photo_limit: 'Please upload an image file that is less than 10MB in size and in the format of bmp, jpeg, jpg, or png"',
  fr_photo_drag: 'Drag the photo over here',
  fr_photo_or: 'OR',
  fr_photo_browse: 'Browse',
  fr_photo_error_format: 'Not support {fileName} file format',
  fr_photo_error_size: '{fileName} file exceeds 10M',
  fr_photo_error_count: 'Exceeds the number of available photos, please delete them',

  fr_sample_ok_title: 'Correct Example',
  fr_sample_ok_desc1: '70 percent of the vertical height of the photograph',
  fr_sample_ok_desc2: 'Must face forward and look directly at the camera',
  fr_sample_ok_desc3: 'Calm expression',
  fr_sample_ok_desc4: 'Sufficient light',
  fr_sample_ok_desc5: 'Clear colorful photo',
  fr_sample_ok_desc6: 'Show eyebrows',
  fr_sample_ok_desc7: 'Only one face in the photograph',
  fr_sample_fail_title: 'Incorrect Example',
  fr_sample_fail_desc1: 'Excessive facial expressions',
  fr_sample_fail_desc2: 'Must not use side faces',
  fr_sample_fail_desc3: 'Must not contain multiple faces',

  fr_create: 'Create Person',
  fr_create_pass: 'Create Person successful',
  fr_create_fail: 'Create Person failed',
  fr_edit: 'Edit Person',
  fr_edit_pass: 'Edit Person successful',
  fr_edit_fail: 'Edit Person failed',
  fr_create_fail1: 'Please provide name or ID',
  fr_feature_upload: 'Upload register photo',
  fr_feature_delete: 'Delete register photo',
  fr_feature_enable: 'Enable register photo',
  fr_feature_enable_hint: 'Please enable the person at first',
  fr_feature_disable: 'Disable register photo',

  // 物件辨識
  or_class_1: 'Person',
  or_class_2: 'Car',
  or_class_3: 'Bicycle',
  or_class_4: 'Truck',
  or_class_5: 'Bus',
  or_cloud: 'Cloud',
  or_full_view: 'Full View',

  // 縮時攝影
  time_lapse_task: '任務設定',
  time_lapse_export: '任務清單',

  // Dashboard-Device Param ===
  device_param_title: 'Device Parameter',
  device_param_aicam_save_title: 'Set AiCam Parameter',
  device_param_patrol_save_title: 'Set Patrol Parameter',
  device_param_bovicast_save_title: 'Set Bovicast Parameter',
  // 設備狀態 ---
  device_param_status: 'Device Status',
  // 設備狀態>連線狀態
  // 設備狀態>頻道狀態
  // 設備狀態>流量
  // 設備狀態>名稱
  // 設備狀態>感測器
  // 設備狀態>其他
  // 影像設定 ---
  device_param_video: 'Video Setting',
  device_param_video_quality: 'Quality',
  device_param_video_quality_w: 'Width',
  device_param_video_quality_h: 'Height',
  device_param_video_quality_framerate: 'Frame Rate(fps)',
  device_param_video_quality_bitrate: 'Bitrate(Kbps)',
  // 影像設定>發布
  device_param_video_publish: 'Publish',
  device_param_video_channel_index: 'Publish channel',
  device_param_video_auto_start: 'Auto publish',
  device_param_video_audio_mute: 'Mute',
  device_param_video_code_codec: 'Voice codec',
  device_param_video_osd: 'OSD',
  device_param_video_osd_date_enabled: 'OSD-Time',
  device_param_video_osd_decibel_enabled: 'OSD-Decibel Value',
  device_param_video_osd_signal_info_enabled: 'OSD-Signal Strength',
  device_param_video_osd_battery_level_percentage_enabled: 'OSD-Battery power （％）',
  device_param_video_osd_battery_voltage_enabled: 'OSD-Battery power（v）',
  device_param_video_customize_quality: 'Customize Quality',
  device_param_video_bitrate_mode: 'Bitrate mode',
  // 影像設定>錄影
  device_param_video_record: 'Record',
  device_param_video_record_duration: 'Video length',
  // 參數設定 ---
  device_param_setting: 'Parameter Setting',
  device_param_setting_charging_control: 'Device Charging Control',
  // 參數設定>介面
  device_param_setting_interface: 'Interface',
  device_param_setting_btn_long_press_time: 'Button Press Time',
  // 參數設定>語音通話
  device_param_setting_voice_call: 'Voice Call',
  device_param_setting_auto_answer: 'Auto Answer',
  // 參數設定>公告
  device_param_setting_announcement_notify_type: 'Voice broadcast',
  device_param_setting_announcement_notify_type_0: 'None',
  device_param_setting_announcement_notify_type_1: 'Voice',
  device_param_setting_announcement_notify_type_2: 'Hint voice', // 提示音
  device_param_setting_announcement_display_type: 'Display',
  device_param_setting_announcement_display_type_0: 'Not display',
  device_param_setting_announcement_display_type_1: 'Display once',
  device_param_setting_announcement_display_type_2: 'Forever',
  // 參數設定>事件
  device_param_setting_notify_in_app: 'Event notify from App',
  device_param_setting_notify_in_app_0: 'Never',
  device_param_setting_notify_in_app_1: 'Always',
  device_param_setting_notify_in_app_2: 'When hide view',
  device_param_setting_notify_in_app_3: 'When display view',
  device_param_setting_wake_up_screen: 'Event trigger view',
  device_param_setting_audio_notify_type: 'Hint voice',
  device_param_potral_setting_audio_notify_type_0: 'None',
  device_param_potral_setting_audio_notify_type_1: 'Alarm voice',
  device_param_potral_setting_audio_notify_type_2: 'Voice prompts', // 語音提示
  device_param_bovicast_setting_audio_notify_type_0: 'None',
  device_param_bovicast_setting_audio_notify_type_1: 'Hint voice',
  device_param_setting_announce_mission_note: 'Announce mission note',
  device_param_setting_announce_tag_note: 'Announce tag note',
  device_param_setting_chase_event_popup_time: 'Close chase time',
  device_param_setting_jpg_quality: 'JPG quality',
  device_param_setting_crop_jpg_quality: 'Crop JPG quality',
  device_param_setting_enable_vibration: 'Vibration prompt',
  // 參數設定>診斷
  device_param_setting_diagnose: 'Diagnose',
  device_param_setting_min_audio_volume: 'Min audio volume',
  // 參數設定>儲存裝置
  device_param_setting_disk: 'Disk',
  device_param_setting_disk_threshold: 'Threshold',
  device_param_setting_disk_cleanup_type: 'Clean up',
  device_param_setting_disk_cleanup_type_0: 'Cannot remove',
  device_param_setting_disk_cleanup_type_1: 'Remove record at first',
  device_param_setting_disk_cleanup_type_2: 'Remove event at first',
  // 參數設定>定位
  device_param_setting_locate: 'Location',
  device_param_setting_post_duration: 'Update duration',
  // 參數設定>AI
  // device_param_setting_ai: 'AI',
  // device_param_setting_ai_hidden: 'AI Mode',
  // device_param_setting_ai_hidden_0: 'Close AI',
  // device_param_setting_ai_hidden_1: 'Open AI-General',
  // device_param_setting_ai_hidden_2: 'Open AI-Silence',
  // 參數設定>工作日誌
  device_param_setting_log: 'Log',
  device_param_setting_log_keep_days: 'Keep Days',
  // 參數設定>人物
  device_param_setting_fr: 'Person recognition',
  // 參數設定>TW車牌
  device_param_setting_lprTw: 'TW vehicle recognition',
  // 參數設定>JP車牌
  device_param_setting_lprJp: 'JP vehicle recognition',
  // 參數設定>VN車牌
  device_param_setting_lprVn: 'VN vehicle recognition',
  // 參數設定>物件
  device_param_setting_or: 'Object recognition',

  // 日誌 =====================================================================
  log_access: 'Access',
  log_resource: 'Resource',
  log_account_type: 'Account',
  log_account_type_user: 'User',
  log_account_type_device: 'Device',
  log_search_placeholder: 'Search account',
  log_search_placeholder_user: 'Search user',
  log_search_placeholder_device: 'Search device',
  log_timestamp: 'Operating Time',
  log_operator: 'Operator',
  log_operator_group: 'Operator Group',
  log_ip: 'IP',
  log_content: 'Content',
  log_target: 'Target',

  // codebook ----------------------------------------------------------------
  log_codebook_0: 'Login',
  log_codebook_1: 'Login ok',
  log_codebook_2: 'Login fail',

  // 群組 ---
  log_codebook_100: 'Group',
  log_codebook_101: 'Create group',
  log_codebook_102: 'Edit group',
  log_codebook_103: 'Delete group',
  // log_codebook_104: 'create_group_license',
  // log_codebook_105: 'edit_group_license',
  // log_codebook_106: 'delete_group_license',

  // 使用者 ---
  log_codebook_200: 'User',
  log_codebook_201: 'Create user',
  log_codebook_202: 'Edit user',
  log_codebook_203: 'Delete user',
  // log_codebook_204: 'create_user_license',
  // log_codebook_205: 'edit_user_license',
  // log_codebook_206: 'delete_user_license',
  log_codebook_207: 'Verify user email',
  log_codebook_208: 'Unlock Account',
  log_codebook_209: 'Lock Account',

  // 設備 ---
  log_codebook_300: 'Device',
  log_codebook_301: 'Create device',
  log_codebook_302: 'Edit device',
  log_codebook_303: 'Delete device',

  // 伺服器 ---
  log_codebook_400: 'Server',
  log_codebook_401: 'Create server',
  log_codebook_402: 'Edit server',
  log_codebook_403: 'Delete server',

  // 發布 ---
  log_codebook_500: 'Live',
  log_codebook_501: 'Live ok',
  log_codebook_502: 'Live fail',

  // 觀看 ---
  log_codebook_600: 'View',
  log_codebook_601: 'View ok',
  log_codebook_602: 'View fail',

  // 歷史影像 ---
  log_codebook_700: 'Video',
  log_codebook_701: 'Create video',
  log_codebook_702: 'Edit video',
  log_codebook_703: 'Delete video',
  log_codebook_704: 'Playback video ok',
  log_codebook_705: 'Playback video fail',
  log_codebook_706: 'Download video ok',
  log_codebook_707: 'Download video fail',

  // // [legacy] Jager Eye config ---
  // log_codebook_800: 'Jager Eye',
  // log_codebook_801: 'create_jager_eye_config',
  // log_codebook_802: 'edit_jager_eye_config',
  // log_codebook_803: 'delete_jager_eye_config',
  // log_codebook_804: 'enable_jager_eye_config',
  // log_codebook_805: 'disable_jager_eye_config',
  // log_codebook_806: 'edit_jager_eye_event',
  // log_codebook_807: 'delete_jager_eye_evnet',

  // 人辨 ---
  log_codebook_900: 'FR',
  log_codebook_901: 'Create FR config',
  log_codebook_902: 'Edit FR config',
  log_codebook_903: 'Delete FR config',

  // 設備控制 ---
  log_codebook_1000: 'Device Control',
  log_codebook_1001: 'Device Control ok',
  log_codebook_1002: 'Device Control fail',

  // 遠端控制 ---
  log_codebook_1100: 'Remote Control',
  log_codebook_1101: 'Remote Control ok',
  log_codebook_1103: 'Remote Control fail',

  // 車牌辨識 ---
  log_codebook_1200: 'LPR',
  log_codebook_1201: 'Create LPR config',
  log_codebook_1202: 'Edit LPR config',
  log_codebook_1203: 'Delete LPR config',
  log_codebook_1204: 'Create LPR vehicle',
  log_codebook_1205: 'Edit LPR vehicle',
  log_codebook_1206: 'Delete LPR vehicle',
  log_codebook_1207: 'Edit LPR snapshot',
  log_codebook_1208: 'Delete LPR snapshot',
  log_codebook_1209: 'Edit LPR event',
  log_codebook_1210: 'Delete LPR event',
  log_codebook_1211: 'Create LPR tag',
  log_codebook_1212: 'Edit LPR tag',
  log_codebook_1213: 'Delete LPR tag',

  // 角色 ---
  log_codebook_1300: 'Role',
  log_codebook_1301: 'Create user role',
  log_codebook_1302: 'Edit user role',
  log_codebook_1303: 'Delete user role',

  // 物件辨識事件 ---
  log_codebook_1400: 'OR',
  log_codebook_1401: 'Edit OR event',
  log_codebook_1402: 'Delete OR event',
}

// console.log(`en:`, Object.keys(en).length)

export default en
