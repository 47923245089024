import { 
  apiSearchVideo,
  apiGetVideoGPS,
} from '@/api/index.js'
import moment from 'moment'

const initialState = () => ({
  eventInfo: {}, // 事件資料
  searchLoading: false, // 搜尋中
  queryParams: {}, // 搜尋條件
  total: 0, // video 總數
  videoList: [], // video list 影片從新到舊排列
  videoGpsList: [], // 紀錄 video GPS 位置資料 videoGpsList = [ [gpsList], [gpsList], ..... ]
  videoIndex: -1, // 回放 video index, 0是最新的影片，越大越舊
  videoTime: '', // 回放 video 時間
  videoPathMap: {}, // 紀錄 video 與其 GPS path 資料的對應
  videoFocusIndex: -1, // video focus index 點擊事件卡片的index，初始值設為-1, 為了watch其變為０時也能更新focus
  isBackPlay: false, // 是否回放 for EventVideo
  isDashboardBackPlay: false, // 是否回放 for Dashboard
  isShowEventVideoPanel: false, // 是否顯示事件影片視窗
  isShowVideoDownloadPanel: false, // 是否顯示影片下載視窗(可設定時間區間搜尋)
  isQueryByTime: false, // 下載視窗是否透過時間搜尋
  hlsVideoList: [], // 暫存 HLS video list
  isMapDraggable: false, // 地圖是否可拖動(不可拖動則tracking該設備路徑 / 可拖動則可以自由移動地圖)
  playSpecificTime: '', // 播放特定時間, 用於回放特定時間的影片
  caller: 'history', // 辨別是 history or dashboard 在使用
  eventVideoList: [], // 該事件的影片陣列
  eventVideoIndex: -1, // 該事件的影片index
  eventVideoTotal: 0, // 該事件的影片總數

  // 以下為搜尋用 for VideoDownload 元件
  queryVideoParams: {}, // 搜尋條件
  queryVideoList: [], // 搜尋的影片陣列
  queryVideoIndex: -1, // 搜尋的影片index
  queryVideoTotal: 0, // 搜尋的影片總數
})

const state = initialState()
const getters = {
  wholeVideoPath(state) {
    if (state.videoGpsList.length === 0 || state.videoIndex === -1) return []
    
    const pathIdx = state.videoPathMap[state.videoIndex]
    if (!state.videoGpsList[pathIdx] || state.videoGpsList[pathIdx].length === 0) return []
    
    return state.videoGpsList[pathIdx].map((item) => ({
      lock: item.lock,
      timestamp: item.timestamp,
      lat: item.latitude,
      lng: item.longitude,
    }))
  },
  videoPath(state, getters) {
    if (getters.wholeVideoPath.length === 0) return []

    return getters.wholeVideoPath
      .filter(item => new Date(state.videoTime).getTime() >= new Date(item.timestamp).getTime())
  },
}
const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  updateEventInfo(state, info) {
    state.eventInfo = info
  },
  updateSearchLoading(state, value) {
    state.searchLoading = value
  },
  updateQueryParams(state, queryParams) {
    state.queryParams = queryParams
  },
  updateTotal(state, total) {
    state.total = total
  },
  updateVideoList(state, videoList) {
    state.videoList = videoList
  },
  addVideoList(state, list) {
    state.videoList.push(...list)
  },
  updateVideoGpsList(state, list) {
    // gpsList 根據timestamp 由舊到新進行排序
    list.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
    state.videoGpsList = list
  },
  addVideoGpsList(state, list) {
    // videoGpsList = [ [gpsList], [gpsList], ..... ]
    state.videoGpsList.push(list)
  },
  updateVideoIndex(state, videoIndex) {
    state.videoIndex = videoIndex
  },
  updateVideoTime(state, videoTime) {
    state.videoTime = videoTime
  },
  updateVideoPathMap(state, videoPathMap) {
    state.videoPathMap = videoPathMap
  },
  updateVideoFocusIndex(state, videoFocusIndex) {
    state.videoFocusIndex = videoFocusIndex
  },
  updateIsBackPlay(state, value) {
    state.isBackPlay = value
  },
  updateIsDashboardBackPlay(state, value) {
    state.isDashboardBackPlay = value
  },
  updateIsShowEventVideoPanel(state, value) {
    state.isShowEventVideoPanel = value
  },
  updateIsShowVideoDownloadPanel(state, value) {
    state.isShowVideoDownloadPanel = value
  },
  updateIsQueryByTime(state, value) {
    state.isQueryByTime = value
  },
  addHlsVideoList(state, list) {
    state.hlsVideoList.push(...list)
  },
  updateHlsVideoList(state, list) {
    state.hlsVideoList = list
  },
  updateIsMapDraggable(state, value) {
    state.isMapDraggable = value
  },
  updatePlaySpecificTime(state, value) {
    state.playSpecificTime = value
  },
  updateCaller(state, caller) {
    state.caller = caller
  },
  updateEventVideoList(state, list) {
    state.eventVideoList = list
  },
  addEventVideoList(state, list) {
    state.eventVideoList.push(...list)
  },
  updateEventVideoIndex(state, videoIndex) {
    state.eventVideoIndex = videoIndex
  },
  updateEventVideoTotal(state, total) {
    state.eventVideoTotal = total
  },
  updateQueryVideoParams(state, queryParams) {
    state.queryVideoParams = queryParams
  },
  updateQueryVideoList(state, list) {
    state.queryVideoList = list
  },
  updateQueryVideoIndex(state, videoIndex) {
    state.queryVideoIndex = videoIndex
  },
  updateQueryVideoTotal(state, total) {
    state.queryVideoTotal = total
  },
}
const actions = {
  async getEventVideoList({ commit, state, dispatch }, payload) {
    try {
      commit('updateSearchLoading', true)
      const res = await apiSearchVideo(payload)
      commit('addEventVideoList', res.data.videoList)
      commit('updateEventVideoTotal', res.data.total)

      // 若還有video，再次呼叫 api
      if (state.eventVideoList.length === payload.count) {
        payload.index += payload.count
        await dispatch('getEventVideoList', payload)
      }
    } catch (err) {
      console.error(`[Store.video.getEventVideoList] `, err)
    } finally {
      commit('updateSearchLoading', false)
    }
  },
  async getVideoList({ commit, state, dispatch }, payload) {
    payload.index = payload.index || 0
    payload.count = payload.count || 20 // count 最大值：100

    commit('updateQueryParams', payload)

    try {
      const res = await apiSearchVideo(payload)
      // const mp4Video = res.data.videoList.filter((video) => video.type === 'MP4')
      // const notMp4Video = res.data.videoList.filter((video) => video.type !== 'MP4')

      // if (notMp4Video.length > 0) {
      //   commit('addHlsVideoList', notMp4Video)
      // }
      commit('addVideoList', res.data.videoList)
      commit('updateTotal', res.data.total)

      // 還有video，再次呼叫 api
      if (state.videoList.length < state.total) {
        // payload.index = state.videoList.length
        payload.index += payload.count
        await dispatch('getVideoList', payload)
      }
    } catch (err) {
      console.error(`[Store.video.getVideoList] `, err)
    }
  },
  async getQueryVideoList({ commit }, payload) {
    try {
      commit('updateSearchLoading', true)
      commit('updateQueryVideoParams', payload)
      const res = await apiSearchVideo(payload)
      commit('updateQueryVideoTotal', res.data.total)
      commit('updateQueryVideoList', res.data.videoList)
      commit('updateQueryVideoIndex', -1)
    } catch (err) {
      commit('updateQueryVideoTotal', 0)
      commit('updateQueryVideoList', [])
      commit('updateQueryVideoIndex', -1)
      console.error(`[Store.video.getQueryVideoList] `, err)
    } finally {
      commit('updateSearchLoading', false)
    }
  },  
  async getVideoGpsList({ commit, state, dispatch }, payload) {
    try {
      const startPlayTime = payload.startPlayTime // 記錄開始播放時間
      if (payload.startPlayTime) delete payload.startPlayTime

      commit('updateSearchLoading', true)
      await dispatch('resetVideoGpsPath')
      await dispatch('getVideoList', payload)

      // 因為關檔異常會殘留異常的HLS video，所以要過濾掉
      const temp = state.videoList.filter((item) => item.type === 'HLS')
      const errHlsIds = []
      temp.forEach(item => {
        // 計算影片開始時間距離現在時間的秒數，若超過5分鐘(關檔時間)則移除
        const diff = moment().diff(moment(item.startTime), 'seconds')
        if (diff > 300) {
          errHlsIds.push(item.id)
        }
      })
      const video = state.videoList.filter((item) => errHlsIds.indexOf(item.id) === -1)
      commit('updateVideoList', video)
      commit('updateTotal', video.length)

      // 找出第一個符合事件時間的影片
      const checkTime = moment(moment(startPlayTime).toISOString())
      const index = state.videoList.findIndex(item => {
        let s = moment(item.startTime)
        let e = moment(item.stopTime)
        return checkTime.isBetween(s, e, null, '[)') // [) 大於等於, 小於
      })
      // console.log('(1)找出符合事件時間的影片 index = ', index)
      
      // // 若沒有找到對應的影片，則重新搜尋最接近的影片
      // if (index === -1) {
      //   dispatch('setClosedVideoIndex', startPlayTime)
      //   console.log('(2)用setClosedVideoIndex 找出符合事件時間的影片 index = ', index)
      // } else {
      //   commit('updateVideoIndex', index)
      // }

      if (state.caller !== initialState().caller) return

      // 取得 event video id
      const eventVideoIds = state.eventVideoList.map(item => item.id)
      const eventVideoLen = eventVideoIds.length
      const idStart = (eventVideoLen === 0) ?
        -1 : state.videoList.findIndex(item => item.id === eventVideoIds[0])
      const idEnd = (eventVideoLen === 0) ? 
        -1 : state.videoList.findIndex(item => item.id === eventVideoIds[eventVideoLen - 1])

      const videoGpsMap = {}
      for (let i = 0; i < idStart; i++) {
        const res = await apiGetVideoGPS(state.videoList[i].id)
        videoGpsMap[i] = i
        if (res.data.gpsList) // 沒有資料會回傳 res.data.gpsList = null
          commit('addVideoGpsList', res.data.gpsList)
        else 
          commit('addVideoGpsList', [])
      }

      if (idStart !== -1 && idEnd !== -1) {
        let gpsList = [] // 紀錄所有 event videos gps list
        for (let j = 0; j < eventVideoLen; j++) {
          const res = await apiGetVideoGPS(eventVideoIds[j])
          videoGpsMap[idStart + j] = idStart
          if (res.data.gpsList) // 沒有資料會回傳 res.data.gpsList = null
            gpsList = [...gpsList, ...res.data.gpsList]
        }
        // 依timestamp排序, 由舊到新
        gpsList.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
        commit('addVideoGpsList', gpsList)
      }

      let pt = idStart
      for (let k = idEnd + 1; k < state.videoList.length; k++) {
        const res = await apiGetVideoGPS(state.videoList[k].id)
        videoGpsMap[k] = ++pt
        if (res.data.gpsList) // 沒有資料會回傳 res.data.gpsList = null
          commit('addVideoGpsList', res.data.gpsList)
        else 
          commit('addVideoGpsList', [])
      }
      commit('updateVideoPathMap', videoGpsMap)
      // 在取得GPS資料後，再設定回放影片(若先更新，會導致回放先前播放影片，再跳至新影片)
      commit('updateVideoIndex', index)
    } catch (err) {
      console.error(`[Store.video.initEventVideos] `, err)
    } finally {
      commit('updateSearchLoading', false)
    }
  },
  // 取得該時間點的影片index
  setVideoIndex({ commit, state }, time ) {
    const checkTime = moment(moment(time).toISOString())
    let index = state.videoList.findIndex(item => {
      let s = moment(item.startTime)
      let e = moment(item.stopTime)
      return checkTime.isBetween(s, e, null, '[)') // [) 大於等於, 小於
    })

    // if (index === -1) {
    //   // 找出最接近的影片, 利用checkTime 與每段影片時間差值最小的index
    //   // 若差值小於 30 秒，則設定該影片為回放影片
    //   const minThreshold = 30 // 30秒
    //   const diffList = state.videoList.map(item => Math.abs(moment(item.startTime).diff(checkTime, 'seconds')))
    //   const min = diffList.indexOf(Math.min(...diffList))
    //   index = diffList[min] <= minThreshold ? min : -1
    // }
    commit('updateVideoIndex', index)
  },
  setClosedVideoIndex({ commit, state }, time) {
    const checkTime = moment(new Date(time).toISOString())
    let index = state.videoList.findIndex(item => {
      let s = moment(item.startTime)
      let e = moment(item.stopTime)
      return checkTime.isBetween(s, e, null, '[)') // [) 大於等於, 小於
    })

    if (index === -1) {
      // 找出最接近的影片, 利用checkTime 與每段影片時間差值最小的index
      // 若差值小於 30 秒，則設定該影片為回放影片
      const minThreshold = 30 // 30秒
      const diffList = state.videoList.map(item => Math.abs(moment(item.startTime).diff(checkTime, 'seconds')))
      const min = diffList.indexOf(Math.min(...diffList))
      index = diffList[min] <= minThreshold ? min : -1
    }
    commit('updateVideoIndex', index)
  },
  async initEventVideos({ commit, state, dispatch }, payload) {
    try {
      // 紀錄事件的起迄時間、postId
      const eventInfo = {
        postId: payload.postId,
        startTime: payload.startTime,
        stopTime: payload.stopTime,
        eventType: payload.eventType,
      }
      commit('updateEventInfo', eventInfo)

      // 取得該事件的影片，歷史事件以事件起迄時間向前後多取 3 分鐘 / 歷史影片即為該事件(事件＝影片)      
      await dispatch('resetVideoGpsPath')
      
      const params1 = { postId: payload.postId }
      params1.startTime = moment(payload.startTime).subtract(3, 'minutes').toISOString()
      params1.stopTime = moment(payload.stopTime).add(3, 'minutes').toISOString()
      params1.index = 0
      params1.count = 100
      // 若是歷史影片，已取得該事件為他的影片
      if (payload.eventType !== 'video') {
        commit('updateEventVideoList', [])
        commit('updateEventVideoTotal', 0)
        await dispatch('getEventVideoList', params1)
      }

      // 取得時間軸的影片, 取得該事件的影片，歷史事件以事件起迄時間向前後各多取 2 小時
      const params2 = { postId: payload.postId }
      params2.startTime = moment(payload.startTime).subtract(120, 'minutes').toISOString()
      params2.stopTime = moment(payload.stopTime).add(120, 'minutes').toISOString()
      params2.index = 0
      params2.count = 100
      params2.startPlayTime = payload.startTime // 設定開始播放時間
      await dispatch('getVideoGpsList', params2)

      // 若沒有找到對應的影片，則重新搜尋最接近的影片
      if (state.videoIndex === -1) {
        dispatch('setClosedVideoIndex', payload.startTime)
      }

      // queryVideoList 初始值為該事件的影片
      commit('updateQueryVideoList', state.eventVideoList)
      commit('updateQueryVideoTotal', state.eventVideoTotal)
      commit('updateQueryVideoParams', params1)
    } catch (err) {
      console.error(`[Store.video.initEventVideos] `, err)
    } finally {
      commit('updateSearchLoading', false)
    }
  },
  resetVideoGpsPath({ commit }) {
    commit('updateVideoIndex', -1)
    commit('updateVideoTime', '')
    commit('updateTotal', 0)
    commit('updateVideoList', [])
    commit('updateHlsVideoList', [])
    commit('updateVideoGpsList', [])
    commit('updateVideoPathMap', {})
    
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}