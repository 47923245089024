// 強制 2FA
export const euForced2fa = {
  on: 1, // 強制
  off: 0, // 按需 default
}
export const forced2faOptions = Object.keys(euForced2fa).map((key) => {
  const value = euForced2fa[key]
  return { label: `account_forced2fa_${value}`, value }
})

// 客戶
export const sysCustomize = {
  bovia: `Bovia`,
  npa: 'NPA'
}
export const getCustomizeValue = (str) => {
  const key = str.toLowerCase()
  return key
}

// 客製功能
export const euCustomizeFunction = {
  park: 0,
}
export const customizeFuncKeys = Object.keys(euCustomizeFunction)
